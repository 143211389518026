export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const API_ERROR = 'LOGIN_API_ERROR'

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN'
export const LOAD_USER = 'LOAD_USER'
export const AUTH_FAIL = 'AUTH_FAIL'

export const PREMIER_USER_DETAIL = 'PREMIER_USER_DETAIL'
export const PREMIER_USER_DETAIL_SUCCESS = 'PREMIER_USER_DETAIL_SUCCESS'