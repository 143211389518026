import React, { useEffect, useState } from 'react'
import { Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import PaymentForm from '../../../components/PaymentForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { paymentIntentAction } from '../../../store/actions'
import { useHistory } from 'react-router-dom'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)
const PremierCheckout = (props) => {
  const [termAndCondition, setTermAndCondition] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { paymentIntent } = useSelector((state) => state.PremierMembership)
  const { premierUser } = useSelector((state) => state.Login)
  useEffect(() => {
    if (premierUser?.premier_exhibitor_id) {
      history.push('/')
    }
  }, [premierUser?.premier_exhibitor_id])
  const appearance = {
    theme: 'stripe',
  }

  useEffect(() => {
    const body = {
      payment_type: 'online',
    }
    dispatch(paymentIntentAction(body))
  }, [])
  const options = {
    clientSecret: paymentIntent?.clientSecret,
    appearance,
    hidePostalCode: true,
    country: true,
  }
  return (
    <div className='page-content bg-gray-101 min-h-85'>
      <section id='about' className='section1 pages'>
        <Container fluid={true} className='auto-fit'>
          <Row>
            <Col lg={12} className='text-center'>
              <div className='page-title-box justify-content-between'>
                <h2 className='top-title mb-4'>
                  <span>PROCESS PAYMENT</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div className='table-responsive'>
                <Table className='table mb-0 cust-tab tab-img'>
                  <tbody className='table-color text-white '>
                    <tr>
                      <td className='text-right'>
                        <h4 className='mb-0'>
                          <strong>Final Payment</strong>
                        </h4>
                      </td>
                      <td style={{ width: 140 }}>
                        <h4 className='mb-0'>
                          <strong>
                            $
                            {(
                              (paymentIntent?.paymentIntent?.amount || 0) / 100
                            ).toFixed(2)}
                          </strong>
                        </h4>{' '}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {paymentIntent?.clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  {/* <CheckoutForm isDisabled={paymentIntent?.clientSecret} /> */}
                  <PaymentForm
                    returnUrl={`${process.env.REACT_APP_URL}premier-membership/premier-membership-purchased`}
                    // returnUrl={`http://localhost:3002/premier-membership/premier-membership-purchased`}
                    clientSecret={''}
                    submitBtn={termAndCondition}
                  >
                    <FormGroup check className='mb-2 mt-4'>
                      <Input
                        type='checkbox'
                        className='form-check-input-v2'
                        checked={termAndCondition}
                        onChange={() => setTermAndCondition(!termAndCondition)}
                        id='term-and-condition'
                      />
                      <Label
                        check
                        style={{
                          fontSize: '22px',
                          fontWeight: 600,
                        }}
                        htmlFor='term-and-condition'
                      >
                        All sales are final and non-refundable.
                      </Label>{' '}
                    </FormGroup>
                  </PaymentForm>
                </Elements>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default PremierCheckout