import _ from 'lodash'
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  AUTH_FAIL,
  PREMIER_USER_DETAIL,
  PREMIER_USER_DETAIL_SUCCESS,
} from './actionTypes'

const initialState = {
  error: '',
  accessToken: localStorage.getItem('accessToken'),
  user: localStorage.getItem('authUser')
    ? JSON.parse(localStorage.getItem('authUser'))
    : '',
  userData: null,
  premierUser: {},
  isPremierUser: false,
  premierUserLoading: false,
  isAuth: false,
  loading: true,
}

const login = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        user: payload.user,
        accessToken: payload.token,
        isAuth: true,
        // loading: false,
      }
      break
    case LOGOUT_USER:
      state = {
        ...state,
        user: '',
        token: '',
        loading: false,
        isAuth: false,
        premierUser: {},
      }
      break
    case LOAD_USER:
      state = {
        ...state,
        // loading: false,
      }
      break
    case LOAD_USER_SUCCESS:
      state = {
        ...state,
        userData: payload?.data || null,
        isAuth: true,
        loading: false,
      }
      break
    case AUTH_FAIL:
      localStorage.clear()
      state = {
        ...state,
        user: '',
        token: '',
        loading: false,
        isAuth: false,
      }
      break
    case PREMIER_USER_DETAIL:
      state = {
        ...state,
        premierUserLoading: true,
        premierUser: {},
      }
      break
    case PREMIER_USER_DETAIL_SUCCESS:
      const prm = _.first(payload)
      state = {
        ...state,
        premierUserLoading: false,
        premierUser: prm || {},
        isPremierUser: prm?.plan_expired === 0 ? true : false,
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, isAuth: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login