/* eslint-disable jsx-a11y/no-distracting-elements */
import 'flatpickr/dist/themes/material_blue.css';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDbDate } from '../../helpers/date_helper';
import { getAllEvent } from '../../store/event/actions';
import { setCurrentPage } from '../../store/pagination/actions';
import { EventListItem } from './Components/EventListItem';
import { EventSearch } from './Components/EventSearch';
import { get } from '../../helpers/api_helper';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import { Col } from 'reactstrap';
import PremiumMembership from './Components/Premium/PremiumMembership';
import PremiumModal from './Components/PremiumModal/PremiumModal';
import { getAllLiveEventList, premierUsers } from '../../store/actions';
import LiveEventButton from './Components/LiveEventButton/LiveEventButton';
import { Link } from 'react-router-dom';

const Events = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tickers, setTickers] = useState('');
  useEffect(() => {
    dispatch(setCurrentPage(1));
    if (localStorage.getItem('accessToken')) {
      history.push('/auth-events');
    }
  }, []);
  useEffect(() => {
    // dispatch(getAllEntry());
    // dispatch(getAllExhibitorList());
    // dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    // const interval = setInterval(() => {
    dispatch(getAllLiveEventList());
    // }, 1000)

    // return () => clearInterval(interval)
  }, []);

  const { allEvents, loading, totalPages } = useSelector(({ event }) => event);
  const { premierUser, premierUserLoading } = useSelector(
    (state) => state.Login
  );
  const userData = useSelector((state) => state.Login);
  const { eventList } = useSelector((state) => ({
    eventList: state?.LiveEvent?.eventData?.eventList || [],
  }));

  const { currentPage, perPage } = useSelector(({ pagination }) => pagination);

  const handleNext = (val) => {
    var element = document.querySelectorAll('.toggle-example');
    element?.forEach((elem) => (elem.style.display = 'none'));
    var element2 = document.querySelectorAll('.runningTotal');
    element2?.forEach((elem) => (elem.style.display = 'none'));

    var element1 = document.querySelectorAll('.fa-chevron-circle-up');
    element1?.forEach((elem1) => {
      elem1?.classList.add('fa-chevron-circle-down');
      elem1?.classList.remove('fa-chevron-circle-up');
    });

    dispatch(setCurrentPage(val));
    //alert(val)
    let data = {};
    if (inputs.search !== '') {
      data.search_text = inputs.search;
    }
    if (inputs.startDate) {
      data.start_date = getDbDate(inputs.startDate);
    }
    if (inputs.endDate) {
      data.end_date = getDbDate(inputs.endDate);
    }
    if (inputs.active1 !== 'both') {
      data.event_type = inputs.active1;
    }
    if (inputs.active2 !== 'both') {
      data.show_type = inputs.active2;
    }
    if (inputs.active3 !== 'both') {
      data.show_category = inputs.active3;
    }
    if (inputs.active4 !== 'all') {
      data.breed_category_id = inputs.active4;
    }

    dispatch(getAllEvent(val, perPage, data));
  };

  const [inputs, setInputs] = useState({
    active1: 'both',
    active2: 'both',
    active3: 'both',
    active4: 'all',
  });

  useEffect(() => {
    if (userData?.user?.id) {
      let data = {
        user_id: userData?.user.id,
      };
      dispatch(premierUsers(data));
    }
  }, [userData?.user?.id]);

  useEffect(() => {
    dispatch(getAllEvent(1, perPage, {}));
    get(`/api/v1/notes/admin/all`, {
      params: {
        _sort: 'created_on',
        _order: 'DESC',
        role: 'user',
        publish: 1,
        _limit: 5,
      },
    }).then((res) => {
      if (Array.isArray(res?.data) && res?.data.length > 0) {
        let tickers = _.get(_.first(res?.data), 'notes');

        setTickers(tickers);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <section className="section bg-gray-101 pages event-list mt-10">
        <div className="auto-fit container-fluid">
          <div className="row mb-6">
            {!premierUserLoading &&
            !(premierUser && premierUser?.plan_expired === 0) ? (
              <Col md={12} sm={12} lg={12}>
                <PremiumMembership />
              </Col>
            ) : null}

            {/* {_.size(eventList) &&
            !premierUserLoading &&
            premierUser?.plan_expired === 0 ? (
              <Col md={12} sm={12} lg={12} className="text-center mb-2">
                <LiveEventButton />
              </Col>
            ) : null} */}

            <div className="col-md-12 col-lg-12 col-xs-12">
              <marquee behavior="scroll" scrollamount="5" direction="left">
                <h4 className="text-danger">{tickers}</h4>
              </marquee>
            </div>

            {_.size(eventList) &&
            !premierUserLoading &&
            premierUser?.plan_expired === 0 ? (
              <Col md={12} sm={12} lg={12} className="text-right mb-2">
                <Link to="/live-shows">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/live_results.png`}
                    alt="Live placing logo"
                    className="logo-max-view"
                  />
                </Link>
              </Col>
            ) : null}

            <div className="col-md-12 col-lg-12 col-xs-12 text-center">
              <h2 className="top-title">
                <span>Event List</span>
              </h2>
            </div>
          </div>
          <EventSearch setInputs={setInputs} inputs={inputs} />

          {allEvents?.map((event, index) => {
            return <EventListItem key={index} event={event} />;
          })}

          {loading && (
            <div className="text-center">
              <p style={{ fontSize: 24 }}>Loading...</p>
            </div>
          )}

          {!allEvents && (
            <div
              style={{
                textAlign: 'center',
                fontSize: 'xx-large',
                minHeight: '80vh',
              }}
            >
              {' '}
              No Events Found{' '}
            </div>
          )}
          {totalPages > 0 ? (
            <ReactPaginate
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              nextLabel=">"
              pageCount={totalPages}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination justify-content-center"
              pageLinkClassName="page-link page-link"
              pageClassName="page-item"
              activeClassName="active"
              nextLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              onPageChange={(nextPage) => {
                const { selected } = nextPage;
                const finalPage = (Number(selected) || 0) + 1;
                handleNext(finalPage);
              }}
              forcePage={currentPage - 1}
            />
          ) : null}
        </div>
        <PremiumModal />
      </section>
    </React.Fragment>
  );
};
export default Events;